import {datetimeFormatter, timeFormatter} from '@hconnect/uikit'
import {CollapsibleTableWithoutPagination, ColumnsConfig} from '@hconnect/uikit/src/lib2'
import {Box, TableCell, TableRow, Tooltip, Typography, styled} from '@mui/material'
import moment from 'moment-timezone'
import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {ExpandedGroupIds} from '../../hooks/useExpandGroups'
import {EquipmentPerformance} from '../../types'
import {getTranslationKey} from '../../utils/translation.utils'

import {KpiValue} from './KpiValue'

const StyledTableCell = styled(TableCell)(({theme}) => ({
  fontSize: 16,
  paddingTop: theme.spacing(2.5),
  paddingBottom: theme.spacing(2.5)
}))

type Props = {
  translationPrefix?: string
  showKpiStatusIndicator?: boolean
  data: EquipmentPerformance[]
  expandAll?: boolean
  expandedEquipments?: string[]
  toggleExpandedEquipments?: (id: ExpandedGroupIds) => void
}

export const EquipmentKpiTableOverview: React.FC<Props> = ({
  translationPrefix,
  data,
  showKpiStatusIndicator = false,
  expandedEquipments,
  toggleExpandedEquipments
}) => {
  const {t, i18n} = useTranslation()
  const language = i18n.language

  const config: ColumnsConfig<EquipmentPerformance> = useMemo(() => {
    return [
      {
        key: 'kpiName',
        label: t(getTranslationKey('performance.label.kpi', translationPrefix)),
        template: ({rowData}) => {
          return <Typography variant="h5">{rowData.equipment.text}</Typography>
        },
        headerSx: {
          width: '50%'
        }
      },
      {
        key: 'actual',
        label: t(getTranslationKey('performance.label.actual', translationPrefix)),
        headerSx: {
          textAlign: 'right',
          paddingLeft: '40px'
        }
      }
    ]
  }, [t, translationPrefix])

  return (
    <Box data-test-id="equipment-kpi-table-overview">
      <CollapsibleTableWithoutPagination<EquipmentPerformance>
        config={config}
        data={data}
        expandedRows={expandedEquipments?.map(Number)}
        setExpandedRows={(index: number) => toggleExpandedEquipments?.(String(index))}
        collapseContent={({rowData}) => (
          <>
            {rowData.kpiPerformance?.kpiAssignments.map((kpiAssignment) => {
              const value = parseFloat(kpiAssignment.result.value)
              const kpiBreachSeverity = kpiAssignment.result.kpiBreachSeverity
              const isSameDay = moment(kpiAssignment.result.startTime).isSame(
                kpiAssignment.result.endTime,
                'day'
              )

              const tooltipText = `${datetimeFormatter(
                kpiAssignment.result.startTime,
                language
              )} - ${
                isSameDay
                  ? timeFormatter(kpiAssignment.result.endTime, language)
                  : datetimeFormatter(kpiAssignment.result.endTime, language)
              }`

              return (
                <TableRow
                  key={`table-collapse-row-${kpiAssignment.kpiName}-${kpiAssignment.result.startTime}`}
                  data-test-id={`table-collapse-row-${kpiAssignment.kpiName}-${kpiAssignment.result.startTime}`}
                >
                  <StyledTableCell />
                  <StyledTableCell>
                    <Tooltip arrow title={tooltipText}>
                      <Typography variant="body1">{kpiAssignment.kpiName}</Typography>
                    </Tooltip>
                  </StyledTableCell>
                  <StyledTableCell>
                    <KpiValue
                      value={value}
                      language={language}
                      unit={kpiAssignment.result.unit}
                      showKpiStatusIndicator={showKpiStatusIndicator}
                      kpiBreachSeverity={kpiBreachSeverity}
                      dataTestId={`equipment-kpi-table-threshold-icon-${kpiBreachSeverity}`}
                    />
                  </StyledTableCell>
                </TableRow>
              )
            })}
          </>
        )}
      />
    </Box>
  )
}
